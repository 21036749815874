<template>
  <div id="project-details-create">
    <v-card>
      <v-app-bar
        color="primary"
        dense
        dark
        elevate-on-scroll
        scroll-target="#scrolling-techniques-7"
      >
        <v-toolbar-title>{{ pageTitle }}</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon dark @click="closePrompt">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-card-title></v-card-title>
      <v-card-text>
        <p></p>
        <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
          <b-spinner
            variant="primary"
            type="grow"
            label="Loading..."
          ></b-spinner>
        </div>
        <v-form
          ref="form1"
          v-model="valid1"
          lazy-validation
          v-on:submit.prevent="confirmAlert"
        >
          <v-container class="py-0">
            <v-row wrap>
              <v-col cols="12" sm="6" lg="6" md="6">
                <h6><span class="text-danger">* </span> Event Name</h6>
                <v-text-field
                  class="mt-5"
                  v-model="EventName"
                  placeholder="Event Name"
                  :rules="EventNameRules"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" lg="6" md="6">
                <h6><span class="text-danger">*</span> Event Date</h6>
                <v-dialog
                  ref="dialog"
                  v-model="DateModal"
                  :return-value.sync="EventDate"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="EventDate"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      :rules="EventDateRules"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="EventDate" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="DateModal = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.dialog.save(EventDate)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
              <v-col cols="12" sm="12" lg="12" md="12">
                <h6 class="mycard-text1 fsize-4">
                  <span class="text-danger">* </span>
                  Event Description
                </h6>
                <v-textarea
                  v-model="EventDescription"
                  :rules="EventDescriptionRules"
                  outlined
                  solo
                >
                </v-textarea>
              </v-col>
              <v-col cols="12" md="4">
                <h6 class="mycard-text1 fsize-4">
                  <span class="text-danger">* </span> Upload Event Image <br />
                  <small>(only jpg, png image files are accepted)</small>
                </h6>
                <v-file-input
                  :clearable="false"
                  truncate-length="25"
                  accept="image/png, image/jpeg"
                  placeholder="Upload Event Photo"
                  prepend-icon="mdi-image"
                  append-outer-icon="mdi-delete"
                  v-model="EventImage.UploadedImage"
                  :rules="EventImageRules"
                  @change="addImage(1)"
                >
                  <template v-slot:append-outer>
                    <v-icon color="red" @click="deleteImage(1)">
                      mdi-delete
                    </v-icon>
                  </template>
                </v-file-input>
              </v-col>
              <v-col align="center" cols="12" md="4">
                <h6 class="mycard-text1 fsize-4">
                  Uploaded Image will Appear Here
                </h6>
                <br />
                <v-img
                  :src="EventImage.ImageUrl"
                  height="100"
                  contain
                  v-if="EventImage.ImageUrl != ''"
                />
              </v-col>
            </v-row>
          </v-container>
          <v-row>
            <v-col align="center" cols="12" md="12">
              <v-btn
                :disabled="!valid1"
                @click.prevent="addEvent"
                :loading="SubmitFlag"
                elevation="30"
                shaped
                tile
                medium
                color="#1db954"
                class="font-size-h6 px-6 mr-3 white--text"
              >
                Add
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>
    
<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {},
  props: {
    addRecordPrompt: {
      required: true,
      type: Boolean,
    },
    pageTitle: {
      required: true,
    },
  },
  data() {
    return {
      valid1: true,
      valid2: true,
      LoadingFlag: false,
      SubmitFlag: false,
      SearchFlag: false,

      UploadFlag: false,
      ResultFlag: false,

      addButtonFlag: true,

      eventData: "",
      Project: {},

      // EventDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      EventDate: "",
      EventDateRules: [(v) => !!v || "Event Date is required"],
      DateModal: false,

      EventName: "",
      EventNameRules: [(v) => !!v || "Event Name is required"],

      EventDescription: "",
      EventDescriptionRules: [(v) => !!v || "Event Description is required"],

      EventImage: {},
      EventImageRules: [(v) => !!v || "Event Image is required"],

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
    };
  },
  computed: {},
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        this.CurrentYearId = CurrentJciYearId;
      }
    },
  },
  mounted() {},
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      this.LoadingFlag = false;
    },
    addImage(id) {
      console.log("addImage called");
      console.log({ id });
      switch (id) {
        case 1:
          var file = this.EventImage.UploadedImage;
          console.log({ file });
          var src = URL.createObjectURL(file);
          console.log({ src });
          if (this.EventImage.src) {
            URL.revokeObjectURL(this.EventImage.src);
          }
          var FileType = file.type;
          console.log({ FileType });
          this.EventImage = {
            ImageType: FileType,
            ImageUrl: src,
            UploadedImage: file,
          };
          break;

        default:
          break;
      }
    },
    deleteImage(id) {
      console.log("deleteImage called");
      console.log({ id });
      switch (id) {
        case 1:
          this.EventImage = {
            ImageType: null,
            ImageUrl: null,
            UploadedImage: null,
          };
          break;

        default:
          break;
      }
    },
    closePrompt() {
      console.log("closePrompt called");

      this.Project = {
        EventName: "",
        EventDate: "",
        EventDescription: "",
        EventImageName: "",
        EventImageUrl: "",
        EventImage: {},
      };

      this.$emit("hideAddRecordPrompt", this.Project);
    },
    resetForm() {
      console.log("resetForm called");
      this.EventName = "";
      this.EventDate = "";
      this.EventDescription = "";
      this.EventImage = {};
    },
    addEvent() {
      console.log("addEvent called");

      var validate1 = this.$refs.form1.validate();
      console.log({ validate1 });

      console.log("EventImage = ", this.EventImage);

      var eventImageUrl =
        this.EventImage.ImageUrl == null || undefined
          ? ""
          : [this.EventImage.ImageUrl];
      var eventImageName =
        this.EventImage.UploadedImage.name == null || undefined
          ? ""
          : this.EventImage.UploadedImage.name;

      console.log({ eventImageUrl }, { eventImageName });

      if (validate1 && eventImageName != "") {
        this.Project = {
          EventName: this.EventName,
          EventDate: this.EventDate,
          EventDescription: this.EventDescription,
          EventImageName: eventImageName,
          EventImageUrl: eventImageUrl,
          EventImage: this.EventImage,
        };

        console.log("Project = ", JSON.stringify(this.Project));

        this.$emit("hideAddRecordPrompt", this.Project);
        this.resetForm();
      } else {
        var message = "";
        if (!validate1) {
          message = "Kindly fill all the fields";
        }
        if (this.EventImage == "") {
          message = "Kindly Upload Event Image";
        }
        this.sweetAlert("error", message, false);
      }
    },
    confirmAlert() {
      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);
      if (validate1) {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: `Save`,
          confirmButtonColor: "#3085d6",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#d33",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.submitForm();
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        // this.toast("error", "Kindly fill the required fields", true);
      }
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
<style lang="scss"></style>